import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { shuffleArray } from '../../utilities';

import SEO from '../../components/SEO';
import Body from '../../components/layout/Body';
import ResponsiveContainer from '../../components/layout/ResponsiveContainer';
import ContentWrapper from '../../components/layout/ContentWrapper';

import GetStarted from '../../components/cta/GetStarted';

import { ViewportBreakpoints } from '../../components/cssConstants';

import H1 from '@rotaready/frecl/build/H1';
import H3 from '@rotaready/frecl/build/H3';
import Text from '@rotaready/frecl/build/Text';

const TitleWrapper = styled.div`
  text-align: center;
`;

const Title = styled(H1).attrs({
  uistyle: 'brand160',
})`
  padding-bottom: 20px;
`;

const BlueWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.brand10};
`;

const BlueAngledLine = styled.div`
  margin-top: -700px;
  height: 1000px;
  position: relative;
  z-index: -1;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    transform: skewY(-15deg);
    background-color: ${({ theme: { colors } }) => colors.brand10};
  }
`;

const PeopleContainer = styled.div`
  margin-top: -200px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const Person = styled.div`
  flex: 0 1 auto;
  box-sizing: border-box;
  //cursor: pointer;
  margin: 5px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 20px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Mugshot = styled.div`
  border-radius: 10px;
  overflow: hidden;
  background-color: ${({ theme: { colors } }) => colors.grey10};
  width: 150px;
  height: 150px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    width: 270px;
    height: 270px;
  }

  @media (min-width: ${ViewportBreakpoints.tabletLandscapeUpper}) {
    width: 320px;
    height: 320px;
  }
`;

const Badge = styled.div`
  border-radius: 3px;
  box-shadow: 0 0 4px 1px rgba(71, 91, 104, 0.1);
  border: solid 1px ${({ theme: { colors } }) => colors.grey20};
  background-color: ${({ theme: { colors } }) => colors.white};
  z-index: 1;
  padding: 10px 0;
  margin-top: -35px;
  width: 80%;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 20px 0;
    margin-top: -50px;
    width: 70%;
  }

  @media (min-width: ${ViewportBreakpoints.tabletLandscapeUpper}) {
    width: 65%;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Name = styled(Text).attrs({
  uistyle: 'brand160',
  weight: 600,
})`
  font-size: 16px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    font-size: 18px;
    padding-bottom: 10px;
  }

  @media (min-width: ${ViewportBreakpoints.tabletLandscapeUpper}) {
    font-size: 20px;
    padding-bottom: 10px;
  }
`;

const JobTitle = styled(Text).attrs({
  uistyle: 'primary',
})`
  font-size: 12px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    font-size: 16px;
  }
`;

const ModalContentWrapper = styled.div`
  padding: 60px;
`;

const people = [
  {
    key: 'amelia',
    name: 'Amelia',
    title: 'Customer Success',
    bio: 'Text here',
  },
  {
    key: 'neil',
    name: 'Neil',
    title: 'Customer Success',
    bio: 'Text here',
  },
  {
    key: 'essi',
    name: 'Essi',
    title: 'Customer Success',
    bio: 'Text here',
  },
  {
    key: 'steve',
    name: 'Steve',
    title: 'New Business Lead',
    bio: 'Text here',
  },
  {
    key: 'megan',
    name: 'Megan',
    title: 'Marketing Lead',
    bio: 'Text here',
  },
  {
    key: 'carl',
    name: 'Carl',
    title: 'Co-Founder',
    bio: 'Text here',
  },
  {
    key: 'jamie',
    name: 'Jamie',
    title: 'Co-Founder',
    bio: 'Text here',
  },
  {
    key: 'luke',
    name: 'Luke',
    title: 'CTO',
    bio: 'Text here',
  },
  {
    key: 'shaun',
    name: 'Shaun',
    title: 'UI/UX Lead',
    bio: 'Text here',
  },
  {
    key: 'stella',
    name: 'Stella',
    title: 'Customer Support',
    bio: 'Text here',
  },
  {
    key: 'fiona',
    name: 'Fiona',
    title: 'Customer Support',
    bio: 'Text here',
  },
  {
    key: 'rob',
    name: 'Rob',
    title: 'New Business',
    bio: 'Text here',
  },
  {
    key: 'jordan',
    name: 'Jordan',
    title: 'New Business',
    bio: 'Text here',
  },
  {
    key: 'aaron',
    name: 'Aaron',
    title: 'Software Engineer',
    bio: 'Text here',
  },
];

const header = {
  backgroundColor: 'brand10',
  invertColors: false,
};

class TeamPage extends React.Component {
  constructor(props) {
    super(props);

    shuffleArray(people);

    this.state = {
      modalPersonKey: null,
    };
  }

  onPersonClick = (key) => {
    const person = people.find(p => p.key === key);

    if (!person) {
      return;
    }

    this.setState(() => ({ modalPersonKey: person.key }));
  };

  onModalClose = () => {
    this.setState(() => ({ modalPersonKey: null }));
  };

  render() {
    const {
      data,
    } = this.props;

    const {
      modalPersonKey,
    } = this.state;

    return (
      <Body header={header}>
        <SEO
          title="Meet The Team - Rotaready"
          description="Innovators, Technologists, Marketers, Analysts, Data Scientists and Creatives; meet the people behind Rotaready"
          url="team"
        />

        <BlueWrapper>
          <ResponsiveContainer>
            <ContentWrapper>
              <TitleWrapper>
                <Title text="Meet team Rotaready" />
                <H3 uistyle="brand160" text="A bunch of great people 👋 and 2 dogs 🐶" />
              </TitleWrapper>
            </ContentWrapper>
          </ResponsiveContainer>
        </BlueWrapper>

        <BlueAngledLine />

        <ResponsiveContainer>
          <PeopleContainer>
            {people.map(person => (
              <Person key={person.key} onClick={() => this.onPersonClick(person.key)}>
                <Mugshot>
                  {Object.prototype.hasOwnProperty.call(data, person.key)
                  && (
                    <GatsbyImage
                      image={data[person.key].childImageSharp.gatsbyImageData}
                      alt={person.name}
                      style={{ maxWidth: '360px' }}
                    />
                  )}
                </Mugshot>
                <Badge>
                  <Name text={person.name} />
                  <JobTitle text={person.title} />
                </Badge>

                {/*<Modal*/}
                {/*  isShown={modalPersonKey === person.key}*/}
                {/*  title={person.name}*/}
                {/*  modalWidth={400}*/}
                {/*  onCloseButtonClick={this.onModalClose}*/}
                {/*  onEsc={this.onModalClose}*/}
                {/*  handleOnClickOutside={this.onModalClose}*/}
                {/*  primaryButtonText="Close"*/}
                {/*  onPrimaryButtonClick={this.onModalClose}*/}
                {/*>*/}
                {/*  <ModalContentWrapper>*/}
                {/*    <Text text={person.bio} />*/}
                {/*  </ModalContentWrapper>*/}
                {/*</Modal>*/}
              </Person>
            ))}
          </PeopleContainer>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default TeamPage;

export const query = graphql`
  query {
    amelia: file(relativePath: { eq: "team/amelia.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality:100)
      }
    }

    carl: file(relativePath: { eq: "team/carl.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality:100)
      }
    }

    jamie: file(relativePath: { eq: "team/jamie.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality:100)
      }
    }

    essi: file(relativePath: { eq: "team/essi.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality:100)
      }
    }

    luke: file(relativePath: { eq: "team/luke.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality:100)
      }
    }

    megan: file(relativePath: { eq: "team/megan.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality:100)
      }
    }

    neil: file(relativePath: { eq: "team/neil.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality:100)
      }
    }

    shaun: file(relativePath: { eq: "team/shaun.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality:100)
      }
    }

    steve: file(relativePath: { eq: "team/steve.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality:100)
      }
    }

    stella: file(relativePath: { eq: "team/stella.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality:100)
      }
    }

    fiona: file(relativePath: { eq: "team/fiona.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality:100)
      }
    }

    rob: file(relativePath: { eq: "team/rob.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality:100)
      }
    }

    aaron: file(relativePath: { eq: "team/aaron.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality:100)
      }
    }

    jordan: file(relativePath: { eq: "team/jordan.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality:100)
      }
    }
  }
`;
